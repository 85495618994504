<template>
    <div>
        <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                :before-close="()=>onClose()"
                @open="onOpen"
                class="box_1">

            <div class="base-container give-mark">
                <div class="item" v-loading="loading">
                    <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000"
                                 :initial-index="initialIndex"
                                 :autoplay="false"
                                 :loop="false" arrow="never"
                                 indicator-position="none">
                        <el-carousel-item v-for="(item, index) in dataList" :key="index">
                            <div class="giveMark flex">
                                <!-- left -->
                                <div class="mark_left">
                                    <div class="imgBox img_big dialog">
                                        <el-image
                                                fit="contain"
                                                :src="$store.getters.getFilePath+item.images"
                                                :preview-src-list="[$store.getters.getFilePath+item.images]"
                                        >
                                        </el-image>
                                    </div>
                                    <!--<div class="work-content">
                                        <div>作品编号：{{item.number}}</div>
                                        <div>精度：{{item.precision}}</div>
                                    </div>-->
                                </div>
                                <!-- right -->
                                <div class="mark_right">
                                    <!-- 科目名称 -->
                                    <div class="generalReview aa">
                                        <div class="flex">
                                            <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                            <div class="item-box">作品总数: {{total}}</div>
                                        </div>
                                    </div>

                                    <div style="text-align: center;margin-top: 40px">
                                        <el-button type="primary" @click="setAbnormal(item.id_card, 1)">提交异常</el-button>
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
                </div>
            </div>
        </el-dialog>
        <!--标记异常弹框-->
        <abnormal-box
                :dialogVisibleFu="dialogVisibleFuA"
                :dataObjFu="dataObjFu"
                :typeFu="true"
                @closeAbnormalBox="closeAbnormalBox"
        ></abnormal-box>
    </div>

</template>

<script>
    import {getStudentScoreList, getStudentScoreList2} from "../api/score";
    import AbnormalBox from "./AbnormalBox";

    export default {
        name: "GiveMarkNormal",
        props: {
            queryObjFu: [Object],
            dialogVisibleFu: [Boolean]
        },
        components: {AbnormalBox},
        data() {
            return {
                title: '作品编号：',
                dialogVisible: this.dialogVisibleFu,
                query: {
                    start: 1,
                    size: 1,
                    order: 1,
                    scores: 10,
                },
                total: 0,
                dataList: [],
                loading: false,
                initialIndex: 0,

                dialogVisibleFuA: false,// 控制标记异常弹框显示与隐藏
                dataObjFu: {
                    message: '',
                    id_card: null,
                    status: null// 1-标为异常 2-取消异常
                },
            }
        },
        methods: {
            onOpen() {
                let query = this.queryObjFu;
                let queryKey = this.queryObjFu;
                if (queryKey && Object.keys(queryKey).length > 0) {
                    this.query.start = Number(query.start);
                }
                this.getList();
            },
            onClose(type = false) {
                this.title = "作品编号：";
                this.dialogVisible = false;
                this.$emit('closeDialog', this.dialogVisible, type);
            },
            // 子组件触发，关闭标记异常弹框
            closeAbnormalBox(val, id_card) {
                this.dialogVisibleFuA = val;
                if (id_card) {
                    this.onClose(true)
                }
            },
            // 标记异常
            setAbnormal(id_card, type) {
                this.dataObjFu = {
                    message: '',
                    id_card: id_card,
                    status: type
                }
                this.dialogVisibleFuA = true;
            },
            getList() {
                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestHandle(getStudentScoreList)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestHandle(getStudentScoreList2)
                }
            },
            requestHandle(api) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message)
                        }
                        this.loading = false;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.sum;
                        this.title = '作品编号：' + this.dataList[0]['number'];
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/giveMark";

    .box_1 {
        ::v-deep .el-dialog {
            margin-top: 15vh;
            width: 75vw;
            height: 75vh;
            min-width: 1000px;
            min-height: 600px;
            display: flex;
            flex-flow: column;

            .el-dialog__body {
                flex: 1;

                .item,
                .el-carousel,
                .el-carousel__container,
                .giveMark {
                    height: 100%;
                }

            }
        }
    }
</style>

