<template>
    <div class="base-container">
        <el-card class="box-card">
            <div class="item">
                <ul class="table-area" v-if="dataList.length>0">
                    <li class="grid-item" v-for="(item, index) in dataList" :key="index">
                        <div class="grid-box">
                            <div class="grid-content" @click="openGiveMark(item, index, $event)">
                                <el-image class="work-img" fit="contain" :src="$store.getters.getFilePath+item.images"
                                          lazy/>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <div class="py_btn" @click="toGiveMark(item, index, $event)">查看</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
                <el-pagination
                        v-if="total>0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="query.start"
                        :page-sizes="[8, 12]"
                        :page-size="query.size"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>
        <GiveMarkNormal
                :queryObjFu="queryGMObj"
                :dialogVisibleFu="dialogGMVisible"
                @closeDialog="closeDialog"
        />
    </div>
</template>

<script>
    import {getStudentScoreList, getStudentScoreList2} from "../../api/score";
    import GiveMarkNormal from "../../components/GiveMarkNormal";

    export default {
        name: "normal",
        components: {GiveMarkNormal},
        data() {
            return {
                query: {
                    start: 1,
                    size: 8,
                    order: 1,
                    scores: 10,
                },
                total: 0,
                dataList: [],

                dialogGMVisible: false,
                queryGMObj: {},
                startCutDown: false, // true-评阅完成页数需要减一
            }
        },
        created() {
            let start = window.localStorage.getItem('normal_start');
            let size = window.localStorage.getItem('normal_size');
            if (start && start != 0) {
                this.query.start = Number(start);
            }
            if (size && size != 0) {
                this.query.size = Number(size);
            }
            this.getList();
        },
        methods: {
            // 关闭评阅弹框
            closeDialog(val, type) {
                this.dialogGMVisible = val;
                // 1-打分
                if (type) {
                    if (this.startCutDown) {
                        this.query.start--;
                        window.localStorage.setItem('normal_start', this.query.start);
                    }
                    this.getList();
                }
            },
            // 去评阅-图片
            openGiveMark(row, idx, event) {
                event.preventDefault();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                let query = {
                    start: start,
                }
                this.queryGMObj = query;
                this.dialogGMVisible = true;
            },
            // 去评阅-按钮
            toGiveMark(data, idx, event) {
                event.stopPropagation();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                let query = {
                    start: start,
                }
                this.$router.push({
                    path: '/normal/giveMark',
                    query: query
                });
                window.localStorage.setItem('normal_size', this.query.size);
            },
            handleSizeChange(val) {
                this.query.size = val;
                window.localStorage.setItem('normal_size', this.query.size);
                this.getList();
            },
            handleCurrentChange(val) {
                this.query.start = val;
                window.localStorage.setItem('normal_start', this.query.start);
                this.getList();
            },
            getList() {
                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestHandle(getStudentScoreList)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestHandle(getStudentScoreList2)
                }
            },
            requestHandle(api) {
                this.dataList = [];
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            return this.$message.error(res.data.message)
                        }
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.sum;

                        if (this.query.start > 1 && this.dataList.length == 1) {
                            this.startCutDown = true;
                        } else {
                            this.startCutDown = false;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/list";
</style>
