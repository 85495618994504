import {request} from "./network";
import store from "../store";

// 获取当前登录的老师的已评阅学生
export function getStudentScoreList(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentScoreList",
        data,
    });
}

// 二次评阅 获取当前登录的老师的已评阅学生
export function getStudentScoreList2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentScoreList2",
        data,
    });
}

// 获取已仲裁数据
export function getStudentScoreListEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentScoreListEnd",
        data,
    });
}

// 已打分的学生排序修改
// top-置顶 stick-置底 left-上移 right-下移
export function updateYjOrder(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/updateYjOrder",
        data,
    });
}

// 二次评阅 已打分的学生排序修改
// top-置顶 stick-置底 left-上移 right-下移
export function updateYjOrder2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/updateYjOrder2",
        data,
    });
}

// 已打分的学生修改分数
export function updateYjScore(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/updateYjScore",
        data,
    });
}

// 二次评阅 已打分的学生修改分数
export function updateYjScore2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/updateYjScore2",
        data,
    });
}
