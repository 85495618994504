<template>
    <el-dialog
            title="提交异常"
            :visible.sync="dialogVisible"
            width="520px"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        异常描述：
        <el-input
                type="textarea"
                v-model="dataObj.message"
                :minlength="1"
                :maxlength="50"
                show-word-limit
                placeholder="请输入1-50字以内的异常描述">
        </el-input>

        <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn">取消</el-button>
      <el-button class="confirm" type="primary" @click="confirm">确认</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {setYjAbnormal, delYjScore} from "../api/abnormal";

    export default {
        name: "AbnormalBox",
        props: {
            dialogVisibleFu: Boolean,
            dataObjFu: Object,
            typeFu: {
                type: Boolean,
                default: false,// true-需要先掉接口重置分数
            }
        },
        data() {
            return {
                dataObj: this.dataObjFu,
                dialogVisible: this.dialogVisibleFu,
                isChecked: false,// 防重复点击
            };
        },
        methods: {
            // 重置分数
            delScore() {
                delYjScore({
                    id_card: this.dataObjFu.id_card
                })
                    .then(res => {
                        console.log(res);
                        if (res.data.code != 200) {
                            this.isChecked = false;
                            return this.$message.error(res.data.message);
                        }
                        this.setAbnormal();
                    })
                    .catch(error => {
                        console.log(error);
                        this.isChecked = false;
                    });
            },
            // 标记异常
            setAbnormal() {
                setYjAbnormal(this.dataObj)
                    .then(res => {
                        console.log(res);
                        if (res.data.code != 200) {
                            this.isChecked = false;
                            return this.$message.error(res.data.message);
                        }
                        this.isChecked = false;
                        this.$message.success(res.data.message);
                        this.dialogVisible = false;
                        this.$store.dispatch('GenerateRoutes').then(response => {
                        }).catch(error => {
                        })
                        this.$emit("closeAbnormalBox", this.dialogVisible, this.dataObj.id_card);
                    })
                    .catch(error => {
                        console.log(error);
                        this.isChecked = false;
                    });
            },
            confirm() {
                if (this.isChecked) {
                    return;
                }
                this.isChecked = true;
                if (this.dataObj.message == "") {
                    this.isChecked = false;
                    return this.$message.info('请输入异常描述');
                }
                if (this.typeFu) {
                    this.delScore();
                } else {
                    this.setAbnormal();
                }
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.dialogVisible = false;
                this.$emit("closeAbnormalBox", this.dialogVisible, false);
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
            dataObjFu() {
                this.dataObj = this.dataObjFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    .el-select {
        width: 100%;
    }

    ::v-deep .el-textarea {
        margin-top: 20px;
        height: 80px;

        .el-textarea__inner {
            width: 100%;
            height: 80px;
            /*min-height: 80px !important;*/
        }
    }
</style>




